exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-get-involved-tsx": () => import("./../../../src/pages/get-involved.tsx" /* webpackChunkName: "component---src-pages-get-involved-tsx" */),
  "component---src-pages-governing-charter-tsx": () => import("./../../../src/pages/governing-charter.tsx" /* webpackChunkName: "component---src-pages-governing-charter-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-initiative-tsx": () => import("./../../../src/pages/initiative.tsx" /* webpackChunkName: "component---src-pages-initiative-tsx" */),
  "component---src-pages-our-initiatives-tsx": () => import("./../../../src/pages/our-initiatives.tsx" /* webpackChunkName: "component---src-pages-our-initiatives-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-initiative-tsx": () => import("./../../../src/templates/initiative.tsx" /* webpackChunkName: "component---src-templates-initiative-tsx" */)
}

